import LRUCache from 'lru-cache'
import { api } from '../api/api'

// Constants
const ACCOUNTS_V1 = '/api/v1/accounts'
const ACCOUNTS_V2 = '/api/v2/accounts'
const ACCOUNTS_V3 = '/api/v3/accounts'
const DEFAULT_PAGE = 0
const DEFAULT_PAGE_SIZE = 10
const MAX_PAGE_SIZE = 10000

// Cache
export const cache = new LRUCache({ maxAge: 90000 }) // 90 seconds

// Api
export const getAggregatedTeamApi = () => api.get(`${ACCOUNTS_V1}/teams`)

export const getTeamApi = accountId => api.get(`${ACCOUNTS_V1}/${accountId}/teams`)

export const getTeamForInviteCodeApi = teamInviteCode =>
  api.get(`${ACCOUNTS_V1}/teams/invite-code/${teamInviteCode}`)

export const createAccountApi = (name, companyId) =>
  api.post(`${ACCOUNTS_V1}/teams`, { name, companyId })

export const updateAccountApi = (accountId, emailDomain, allowSelfJoin) =>
  api.patch(`${ACCOUNTS_V1}/${accountId}/teams`, {
    emailDomain,
    privacy: allowSelfJoin ? 'OPEN_TO_DOMAIN' : 'INVITE_ONLY',
  })

export const inviteMembersApi = (accountId, locationId, memberEmails) =>
  api.post(`${ACCOUNTS_V1}/${accountId}/members`, {
    locationId,
    members: memberEmails.map(email => ({ username: email })),
  })

export const getTeamMembersApi = accountId => {
  return api.get(`${ACCOUNTS_V1}/${accountId}/teams/members?page=0&size=${MAX_PAGE_SIZE}`)
}

export const removeMembersFromTeamApi = (accountId, userIds) => {
  const userIdsString = userIds.join(',')
  return api.delete(`${ACCOUNTS_V1}/${accountId}/teams/members?userIds=${userIdsString}`)
}

export const acceptInviteApi = teamInviteCode =>
  api.patch(`${ACCOUNTS_V1}/teams/members/invite.accept`, { teamInviteCode })

export const declineInviteApi = () => api.patch(`${ACCOUNTS_V1}/teams/members/invite.decline`)

export const getTeamPaymentMethodsApi = accountId =>
  api.get(`${ACCOUNTS_V1}/${accountId}/payment-methods`)

export const updateTeamDefaultPaymentMethodApi = (accountId, paymentMethodId) =>
  api.patch(`${ACCOUNTS_V1}/${accountId}/payment-methods/${paymentMethodId}/setDefault`)

export const updateTeamSettingsApi = (accountId, team) =>
  api.patch(`${ACCOUNTS_V1}/${accountId}/teams`, team)

export const getUserPotentialTeamsApi = () => api.get(`${ACCOUNTS_V2}/teams/user-potential-teams`)

export const selfJoinTeamApi = accountId =>
  api.post(`${ACCOUNTS_V1}/${accountId}/teams/members/join`)

export const selfRemoveFromTeamApi = () => api.delete(`${ACCOUNTS_V1}/teams/members`)

export const getInvoicesApi = (accountId, page = DEFAULT_PAGE, pageSize = DEFAULT_PAGE_SIZE) =>
  api.get(
    `${ACCOUNTS_V1}/${accountId}/invoices?page=${page}&size=${pageSize}&sort=billingPeriodEndDate,desc`,
    null,
    {
      cache,
    },
  )

export const getInvoiceReportApi = invoiceId =>
  api.get(`${ACCOUNTS_V1}/invoices/${invoiceId}/report`)

export const getGiftTransactionsApi = (
  accountId,
  page = DEFAULT_PAGE,
  pageSize = DEFAULT_PAGE_SIZE,
) =>
  api.get(
    `${ACCOUNTS_V1}/${accountId}/gift-cards/transactions?page=${page}&size=${pageSize}&sort=createdAt,desc`,
    null,
    { cache },
  )

export const getGiftTransactionDetailsApi = (accountId, giftTransactionId) =>
  api.get(`${ACCOUNTS_V1}/${accountId}/gift-cards/transactions/${giftTransactionId}/details`)

export const createGiftCardsApi = (accountId, payload) =>
  api.post(`${ACCOUNTS_V1}/${accountId}/teams/gift-cards`, payload)

export const getProgramsApi = (accountId, page = DEFAULT_PAGE, pageSize = MAX_PAGE_SIZE) =>
  api.get(`${ACCOUNTS_V1}/${accountId}/programs?page=${page}&size=${pageSize}`)

export const getProgramUsageTypesApi = () => api.get(`${ACCOUNTS_V1}/programs/usage-types`)

export const createProgramApi = (accountId, payload) =>
  api.post(`${ACCOUNTS_V1}/${accountId}/programs`, payload)

export const updateProgramApi = (programId, payload) =>
  api.patch(`${ACCOUNTS_V1}/programs/${programId}`, payload)

export const addProgramMembersApi = (programId, payload) =>
  api.post(`${ACCOUNTS_V2}/programs/${programId}/members.add`, payload)

export const addProgramMembersApiV3 = (programId, payload) =>
  api.post(`${ACCOUNTS_V3}/programs/${programId}/members.add`, payload)

export const removeProgramMembersApi = (programId, userIds) =>
  api.post(`${ACCOUNTS_V2}/programs/${programId}/members.remove`, userIds)

export const getMembersForProgramApi = programId =>
  api.get(`${ACCOUNTS_V1}/programs/${programId}/members?size=${MAX_PAGE_SIZE}`)

export const getProgramStatsApi = programId => api.get(`${ACCOUNTS_V1}/programs/${programId}/stats`)

export const addExternalManagerApi = (accountId, userId) =>
  api.post(`${ACCOUNTS_V1}/${accountId}/teams/managers/${userId}`)

export const removeExternalManagerApi = (accountId, userId) =>
  api.delete(`${ACCOUNTS_V1}/${accountId}/teams/managers/${userId}`)

export const getAvailableOffersApi = accountId =>
  api.get(`${ACCOUNTS_V1}/${accountId}/discount-offers`)

export const getProgramDiscountAmountApi = programValues =>
  api.post(`${ACCOUNTS_V1}/perkTotals/recurring-perk`, programValues)

export const getClaimableTeamApi = companyId =>
  api.get(`${ACCOUNTS_V1}/teams/claimable-team/${companyId}`)

export const validateGiftCardDiscountAmountApi = (discountCode, payload) =>
  api.post(
    `${ACCOUNTS_V1}/discount-offers/${encodeURI(discountCode)}/gift-discount-amounts`,
    payload,
  )
