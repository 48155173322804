import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  setPage,
  setSort,
  toggleSelectAllMembers,
  toggleSelectMember,
  toggleSelectOnePageMembers,
} from '../../../redux/modules/teamAddPerkMembers'
import { addProgramMembersStart } from '../../../redux/modules/teamAddPerkMembers'
import {
  selectMissingMembers,
  selectMissingMembersRows,
} from '../../../redux/selectors/teamAddProgramMembersSelectors'
import { isRowSelected, getFullName } from '../admin/adminUtils'
import HeaderTableMembers from '../common/HeaderTableMembers'
import SortableTable from '../../common/SortableTable'
import StepPerkMemberConfirmation from './StepPerkMemberConfirmation'

const DIALOG_CONTENT_HEIGHT = 340

const STEPS = Object.freeze({
  MEMBERS: 'MEMBERS',
  CONFIRMATION: 'CONFIRMATION',
})

const useStyles = makeStyles(theme => ({
  dialogContent: {
    height: DIALOG_CONTENT_HEIGHT,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

const DialogAddPerkMembers = ({
  perkMembers,
  selectedProgram,
  missingMembers,
  currentPageRows,
  page,
  sort,
  selectedMembers,
  isAddingProgramMembers,
  errorAddingProgramMembers,
  setPage,
  setSort,
  toggleSelectMember,
  toggleSelectOnePageMembers,
  toggleSelectAllMembers,
  addProgramMembersStart,
  onClose,
}) => {
  const classes = useStyles()
  const [validationError, setValidationError] = useState(undefined)
  const [currentStep, setCurrentStep] = useState(STEPS.MEMBERS)

  const handleSelectMember = member => {
    toggleSelectMember(member)
  }

  const handleSelectOnePage = checked => {
    toggleSelectOnePageMembers({ checked, currentPageRows })
  }

  const handleSelectAll = checked => {
    toggleSelectAllMembers({ checked, allMembers: missingMembers })
  }

  const handleSubmit = () => {
    if (selectedMembers.length < 1) {
      setValidationError('Please select at least one team member.')
      return
    }
    const isAddingToProgram = Boolean(selectedProgram?.programId)
    if (isAddingToProgram) {
      addProgramMembersStart(selectedProgram.programId, selectedMembers)
    }
  }

  useEffect(() => {
    if (selectedMembers.length > 0) {
      setValidationError(undefined)
    }
  }, [selectedMembers, setValidationError])

  const columns = [
    {
      id: 'lastName', // This field is named lastName for sorting purposes
      label: 'Name',
      sortable: true,
    },
    {
      id: 'email',
      label: 'Email',
      sortable: true,
    },
  ]

  const rowCells = currentPageRows.map((member, index) => {
    const labelId = `members-table-checkbox-${index}`
    return {
      memberId: member.userId,
      cells: [
        {
          Component: (
            <Checkbox
              key={member.userId}
              checked={isRowSelected(selectedMembers, member)}
              inputProps={{ 'aria-labelledby': labelId }}
              onClick={() => handleSelectMember(member)}
            />
          ),
        },
        {
          labelId: labelId,
          Component: <Typography>{getFullName(member)}</Typography>,
        },
        {
          Component: <Typography>{member.email}</Typography>,
        },
      ],
    }
  })

  const title = currentStep === STEPS.MEMBERS ? 'Add Members' : 'Member Confirmation'
  return (
    <Dialog open fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <IconButton className={classes.closeButton} onClick={onClose}>
        <Close />
      </IconButton>
      {currentStep === STEPS.MEMBERS && (
        <StepMembers
          columns={columns}
          currentPageRows={currentPageRows}
          rowCells={rowCells}
          page={page}
          sort={sort}
          handleSelectOnePage={handleSelectOnePage}
          handleSelectAll={handleSelectAll}
          setPage={setPage}
          setSort={setSort}
          selectedMembers={selectedMembers}
          missingMembers={missingMembers}
          onSubmit={() => setCurrentStep(STEPS.CONFIRMATION)}
        />
      )}
      {currentStep === STEPS.CONFIRMATION && (
        <StepPerkMemberConfirmation
          height={DIALOG_CONTENT_HEIGHT}
          addMode
          perkMembers={perkMembers}
          selectedMembers={selectedMembers}
          errorSubmitting={errorAddingProgramMembers}
          validationError={validationError}
          submitting={isAddingProgramMembers}
          onBack={() => setCurrentStep(STEPS.MEMBERS)}
          onSubmit={handleSubmit}
          pricePerHeadInCents={selectedProgram?.discountAmountInPennies}
        />
      )}
    </Dialog>
  )
}

const StepMembers = ({
  columns,
  currentPageRows,
  rowCells,
  page,
  sort,
  handleSelectOnePage,
  handleSelectAll,
  setPage,
  setSort,
  selectedMembers,
  missingMembers,
  onSubmit,
}) => {
  const classes = useStyles()

  const handleSort = (e, property) => {
    const isAsc = sort.field === property && sort.direction === 'asc'
    setSort({
      field: property,
      direction: isAsc ? 'desc' : 'asc',
    })
  }

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box marginBottom={3}>
          <Typography variant="body2" color="textSecondary">
            Team members selected ({selectedMembers.length})
          </Typography>
        </Box>
        <SortableTable
          data={missingMembers}
          rowCells={rowCells}
          page={page}
          setPage={setPage}
          HeaderComponent={
            <HeaderTableMembers
              checkbox
              teamMembers={missingMembers}
              columns={columns}
              rows={currentPageRows}
              selectedMembers={selectedMembers}
              sort={sort}
              onSelectOnePage={handleSelectOnePage}
              onSelectAll={handleSelectAll}
              onSort={handleSort}
            />
          }
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onSubmit}>
          Next
        </Button>
      </DialogActions>
    </>
  )
}

const mapStateToProps = state => {
  const { perkMembers, selectedProgram } = state.teamPerkDetails
  const missingMembers = selectMissingMembers(state)
  const currentPageRows = selectMissingMembersRows(state)
  const {
    page,
    sort,
    selectedMembers,
    isAddingProgramMembers,
    errorAddingProgramMembers,
  } = state.teamAddPerkMembers

  return {
    perkMembers,
    selectedProgram,
    missingMembers,
    currentPageRows,
    page,
    sort,
    selectedMembers,
    isAddingProgramMembers,
    errorAddingProgramMembers,
  }
}

const mapDispatchToProps = {
  setPage,
  setSort,
  toggleSelectMember,
  toggleSelectOnePageMembers,
  toggleSelectAllMembers,
  addProgramMembersStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogAddPerkMembers)
