import { differenceWith, isEmpty } from 'lodash'
import pluralize from 'pluralize'
import { formatAllMemberNames, isRowSelected } from '../../components/teams/admin/adminUtils'
import { createAction, createAsyncAction, FULFILLED, PENDING, REJECTED } from '../utils'
import { selectTeamMembers } from '../selectors/teamMembersSelectors'
import { selectPerkMembers } from '../selectors/teamProgramDetailsSelectors'
import { addProgramMembersApi } from '../../services/team'
import { enqueueSnackbar } from './snackbar'
import { loadProgramMembersStart } from './teamPerkDetails'

// ------------------------------------
// Action Types & Creators
// ------------------------------------
export const PERK_MEMBERS_ADD = 'foodsby/teamAddPerkMembers/PERK_MEMBERS_ADD'
export const MISSING_MEMBERS_SET = 'foodsby/teamAddPerkMembers/MISSING_MEMBERS_SET'
export const PAGE_SET = 'foodsby/teamAddPerkMembers/PAGE_SET'
export const SEARCH_SET = 'foodsby/teamAddPerkMembers/SEARCH_SET'
export const SORT_SET = 'foodsby/teamAddPerkMembers/SORT_SET'
export const STATE_RESET = 'foodsby/teamAddPerkMembers/STATE_RESET'
export const MEMBER_TOGGLE_SELECT = 'foodsby/teamAddPerkMembers/MEMBER_TOGGLE_SELECT'
export const ONE_PAGE_MEMBERS_TOGGLE_SELECT =
  'foodsby/teamAddPerkMembers/ONE_PAGE_MEMBERS_TOGGLE_SELECT'
export const ALL_MEMBERS_TOGGLE_SELECT = 'foodsby/teamAddPerkMembers/ALL_MEMBERS_TOGGLE_SELECT'

export const addPerkMembers = createAsyncAction(PERK_MEMBERS_ADD)
export const setMissingMembers = createAction(MISSING_MEMBERS_SET)
export const setPage = createAction(PAGE_SET)
export const setSearch = createAction(SEARCH_SET)
export const setSort = createAction(SORT_SET)
export const resetState = createAction(STATE_RESET)
export const toggleSelectMember = createAction(MEMBER_TOGGLE_SELECT)
export const toggleSelectOnePageMembers = createAction(ONE_PAGE_MEMBERS_TOGGLE_SELECT)
export const toggleSelectAllMembers = createAction(ALL_MEMBERS_TOGGLE_SELECT)

// ------------------------------------
// Thunks
// ------------------------------------
export const addProgramMembersStart = (programId, members) => {
  return async dispatch => {
    const memberIds = members.map(m => m.userId)

    try {
      await dispatch(addPerkMembers(addProgramMembersApi(programId, memberIds)))

      createSuccessSnackbarForPerkMembersAdded(dispatch, members)

      dispatch(loadProgramMembersStart(programId))
      dispatch(resetState())
    } catch (ex) {
      dispatch(enqueueSnackbar({ message: 'Something went wrong adding members to the perk.' }))
    }
  }
}

export const setMissingMembersStart = () => {
  return (dispatch, getState) => {
    const state = getState()
    const teamMembers = selectTeamMembers(state)
    const perkMembers = selectPerkMembers(state)

    dispatch(setMissingMembers({ teamMembers, perkMembers }))
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PENDING(PERK_MEMBERS_ADD)]: state => {
    return {
      ...state,
      isAddingProgramMembers: true,
      errorAddingProgramMembers: undefined,
    }
  },
  [FULFILLED(PERK_MEMBERS_ADD)]: state => {
    return {
      ...state,
      isAddingProgramMembers: false,
      errorAddingProgramMembers: undefined,
    }
  },
  [REJECTED(PERK_MEMBERS_ADD)]: (state, action) => {
    return {
      ...state,
      isAddingProgramMembers: false,
      errorAddingProgramMembers: action.payload.message,
    }
  },
  [MISSING_MEMBERS_SET]: (state, action) => {
    const { teamMembers, perkMembers } = action.payload

    const missingMembers = differenceWith(
      teamMembers,
      perkMembers,
      (a, b) => a.userId === b.userId,
    ).filter(member => member.status === 'CONFIRMED')

    if (isEmpty(missingMembers)) {
      return {
        ...state,
        missingMembers: [],
        errorSettingMissingMembers: 'All members of your team are already on this perk.',
      }
    }

    return {
      ...state,
      missingMembers: missingMembers,
      errorSettingMissingMembers: undefined,
    }
  },
  [PAGE_SET]: (state, action) => {
    return {
      ...state,
      page: action.payload,
    }
  },
  [SEARCH_SET]: (state, action) => {
    return {
      ...state,
      search: action.payload,
    }
  },
  [SORT_SET]: (state, action) => {
    return {
      ...state,
      sort: action.payload,
    }
  },
  [STATE_RESET]: () => {
    return initialState
  },
  [MEMBER_TOGGLE_SELECT]: (state, action) => {
    const { selectedMembers } = state
    const member = action.payload

    return {
      ...state,
      selectedMembers: isRowSelected(selectedMembers, member)
        ? selectedMembers.filter(m => m.userId !== member.userId)
        : [...selectedMembers, member],
    }
  },
  [ONE_PAGE_MEMBERS_TOGGLE_SELECT]: (state, action) => {
    const { selectedMembers } = state
    const { checked, currentPageRows } = action.payload

    const selectedRowsOnOtherPages = selectedMembers.filter(
      sm => !currentPageRows.map(r => r.userId).includes(sm.userId),
    )
    return {
      ...state,
      selectedMembers: checked
        ? [...currentPageRows, ...selectedRowsOnOtherPages]
        : selectedRowsOnOtherPages,
    }
  },
  [ALL_MEMBERS_TOGGLE_SELECT]: (state, action) => {
    const { checked, allMembers } = action.payload

    return {
      ...state,
      selectedMembers: checked ? [] : allMembers,
    }
  },
}

export const initialState = {
  missingMembers: [],
  page: 0,
  search: undefined,
  sort: { field: undefined, direction: undefined },
  selectedMembers: [],
  // Loading states
  isAddingProgramMembers: false,
  // Success states
  // Error states
  errorAddingProgramMembers: undefined,
  errorSettingMissingMembers: undefined,
}

export default function teamAddPerkMembers(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Utils
// ------------------------------------
const createSuccessSnackbarForPerkMembersAdded = (dispatch, members) => {
  const memberNames = formatAllMemberNames(members)
  dispatch(
    enqueueSnackbar({
      message: `${memberNames} ${pluralize('has', members.length)} been added to the perk.`,
    }),
  )
}
