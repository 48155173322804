import { first } from 'lodash'

import { subscriptionsCache } from '../../api/api'
import {
  createSubscriptionApi,
  cancelSubscriptionApi,
  getSubscriptionDetailsApi,
  getSubscriptionOfferApi,
  updateSubscriptionApi,
  getIsSubscribed,
} from '../../services/subscriptions'
import { createAsyncAction, FULFILLED, PENDING, REJECTED } from '../utils'

const ERROR_MESSAGE_LOAD_SUBSCRIPTIONS =
  'There was a problem loading your Unlimited Delivery information. Please refresh the page and try again.'
const ERROR_MESSAGE_CANCEL_SUBSCRIPTION =
  'Something went wrong when canceling subscription. Please try again or contact support.'

// ------------------------------------
// Action Types & Creators
// ------------------------------------
export const IS_SUBSCRIBED_SET = 'foodsby/subscriptions/IS_SUBSCRIBED_SET'
export const SUBSCRIPTION_DETAILS_SET = 'foodsby/subscriptions/SUBSCRIPTION_DETAILS_SET'
export const SUBSCRIPTION_OFFER_SET = 'foodsby/subscriptions/SUBSCRIPTION_OFFER_SET'
export const SUBSCRIPTION_SUBMIT = 'foodsby/subscriptions/SUBSCRIPTION_SUBMIT'

export const setIsSubscribed = createAsyncAction(IS_SUBSCRIBED_SET)
export const setSubscriptionDetails = createAsyncAction(SUBSCRIPTION_DETAILS_SET)
export const setSubscriptionOffer = createAsyncAction(SUBSCRIPTION_OFFER_SET)
export const submitSubscription = createAsyncAction(SUBSCRIPTION_SUBMIT)

// ------------------------------------
// Thunks
// ------------------------------------
export const loadIsSubscribedStart = () => dispatch => {
  return dispatch(setIsSubscribed(getIsSubscribed()))
}

export const loadSubscriptionDetailsStart = () => dispatch => {
  return dispatch(setSubscriptionDetails(getSubscriptionDetailsApi()))
}

export const loadSubscriptionOfferStart = () => dispatch => {
  return dispatch(setSubscriptionOffer(getSubscriptionOfferApi()))
}

export const createSubscriptionStart = paymentMethodId => dispatch => {
  return dispatch(submitSubscription(createSubscriptionApi(paymentMethodId))).then(() => {
    subscriptionsCache.reset()
  })
}

export const updateSubscriptionStart = (subscriptionId, paymentMethodId) => dispatch => {
  return dispatch(submitSubscription(updateSubscriptionApi(subscriptionId, paymentMethodId))).then(
    () => {
      subscriptionsCache.reset()
    },
  )
}

export const cancelSubscriptionStart = subscriptionId => dispatch => {
  return dispatch(submitSubscription(cancelSubscriptionApi(subscriptionId))).then(() => {
    subscriptionsCache.reset()
  })
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [FULFILLED(IS_SUBSCRIBED_SET)]: (state, action) => ({
    ...state,
    isSubscribed: action.payload,
  }),
  [PENDING(SUBSCRIPTION_DETAILS_SET)]: state => ({
    ...state,
    isLoadingSubscriptionDetails: true,
  }),
  [FULFILLED(SUBSCRIPTION_DETAILS_SET)]: (state, action) => ({
    ...state,
    subscriptionDetails: first(action.payload) ?? null,
    isLoadingSubscriptionDetails: false,
    errorLoadingSubscriptionDetails: undefined,
  }),
  [REJECTED(SUBSCRIPTION_DETAILS_SET)]: state => ({
    ...state,
    isLoadingSubscriptionDetails: false,
    errorLoadingSubscriptionDetails: ERROR_MESSAGE_LOAD_SUBSCRIPTIONS,
  }),
  [PENDING(SUBSCRIPTION_OFFER_SET)]: state => ({
    ...state,
    isLoadingSubscriptionOffer: true,
  }),
  [FULFILLED(SUBSCRIPTION_OFFER_SET)]: (state, action) => ({
    ...state,
    subscriptionOffer: action.payload,
    isLoadingSubscriptionOffer: false,
  }),
  [REJECTED(SUBSCRIPTION_OFFER_SET)]: state => ({
    ...state,
    subscriptionOffer: undefined,
    isLoadingSubscriptionOffer: false,
  }),
  [PENDING(SUBSCRIPTION_SUBMIT)]: state => ({
    ...state,
    isSubmittingSubscription: true,
    successSubmittingSubscription: false,
    errorSubmittingSubscription: undefined,
  }),
  [FULFILLED(SUBSCRIPTION_SUBMIT)]: state => ({
    ...state,
    isSubmittingSubscription: false,
    successSubmittingSubscription: true,
    errorSubmittingSubscription: undefined,
  }),
  [REJECTED(SUBSCRIPTION_SUBMIT)]: state => ({
    ...state,
    isSubmittingSubscription: false,
    successSubmittingSubscription: false,
    errorSubmittingSubscription: ERROR_MESSAGE_CANCEL_SUBSCRIPTION,
  }),
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  isSubscribed: undefined,
  subscriptionDetails: undefined,
  subscriptionOffer: undefined,
  // Loading states
  isLoadingSubscriptionDetails: false,
  isLoadingSubscriptionOffer: false,
  isSubmittingSubscription: false,
  // Success states
  successSubmittingSubscription: false,
  // Error states
  errorLoadingSubscriptionDetails: undefined,
  errorSubmittingSubscription: undefined,
}

export default function subscriptions(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
