import React, { useEffect, useState } from 'react'
import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  List,
  ListItem,
  Typography,
  DialogContent as MuiDialogContent,
  Button,
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { Alert } from '@material-ui/lab'
import { connect } from 'react-redux'

import { selectActionableMembers } from '../../../redux/selectors/teamProgramDetailsSelectors'
import {
  removeActionableMember,
  removeProgramMembersStart,
  resetPerkMembersRemoveState,
} from '../../../redux/modules/teamPerkDetails'
import ButtonDialogClose from '../common/ButtonDialogClose'
import TableActionableMembers from '../common/TableActionableMembers'
import StepPerkMemberConfirmation from './StepPerkMemberConfirmation'

const DIALOG_CONTENT_HEIGHT = 340

const STEPS = Object.freeze({
  MEMBERS: 'MEMBERS',
  CONFIRMATION: 'CONFIRMATION',
})

const DialogContent = withStyles({
  root: {
    height: DIALOG_CONTENT_HEIGHT,
  },
})(MuiDialogContent)

const DialogRemovePerkMembers = ({
  programId,
  perkMembers,
  removableMembers,
  isRemovingPerkMembers,
  errorRemovingPerkMembers,
  successRemovingPerkMembers,
  removeProgramMembersStart,
  removeActionableMember,
  resetPerkMembersRemoveState,
  onClose,
  pricePerHeadInCents,
}) => {
  const [currentStep, setCurrentStep] = useState(STEPS.MEMBERS)

  useEffect(() => {
    if (successRemovingPerkMembers) {
      resetPerkMembersRemoveState()
      onClose()
    }
  }, [onClose, resetPerkMembersRemoveState, successRemovingPerkMembers])

  const handleSubmit = () => {
    const isRemovingFromProgram = Boolean(programId)
    if (isRemovingFromProgram) {
      removeProgramMembersStart(programId, removableMembers)
    }
  }

  const handleClose = () => {
    onClose()
  }

  const handleExcludeMember = member => {
    removeActionableMember(member)
  }

  const title = currentStep === STEPS.MEMBERS ? 'Remove Members' : 'Member Confirmation'
  return (
    <Dialog open fullWidth maxWidth="sm">
      <DialogTitle>
        {title}
        <ButtonDialogClose onClick={handleClose} />
      </DialogTitle>
      {currentStep === STEPS.MEMBERS && (
        <StepMembers
          removableMembers={removableMembers}
          handleExcludeMember={handleExcludeMember}
          onSubmit={() => setCurrentStep(STEPS.CONFIRMATION)}
        />
      )}
      {currentStep === STEPS.CONFIRMATION && (
        <StepPerkMemberConfirmation
          height={DIALOG_CONTENT_HEIGHT}
          addMode={false}
          perkMembers={perkMembers}
          selectedMembers={removableMembers}
          errorSubmitting={errorRemovingPerkMembers}
          submitting={isRemovingPerkMembers}
          onBack={() => setCurrentStep(STEPS.MEMBERS)}
          onSubmit={handleSubmit}
          pricePerHeadInCents={pricePerHeadInCents}
        />
      )}
      {errorRemovingPerkMembers && (
        <Alert severity="error">
          Something went wrong when removing the member. Please try again or contact support.
        </Alert>
      )}
    </Dialog>
  )
}

const StepMembers = ({ removableMembers, handleExcludeMember, onSubmit }) => {
  return (
    <>
      <DialogContent>
        <Box marginBottom={3}>
          <Typography>What happens when you remove team members from a perk?</Typography>
          <List dense>
            <ListItem>• The members will no longer receive the perk benefits.</ListItem>
            <ListItem>
              • The members will still be on your team and able to order from Foodsby.
            </ListItem>
          </List>
        </Box>

        <Box marginBottom={2}>
          <TableActionableMembers members={removableMembers} onRemove={handleExcludeMember} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onSubmit}>
          Next
        </Button>
      </DialogActions>
    </>
  )
}

const mapStateToProps = state => {
  const removableMembers = selectActionableMembers(state)
  const {
    perkMembers,
    isRemovingPerkMembers,
    errorRemovingPerkMembers,
    successRemovingPerkMembers,
  } = state.teamPerkDetails

  return {
    perkMembers,
    removableMembers,
    isRemovingPerkMembers,
    errorRemovingPerkMembers,
    successRemovingPerkMembers,
  }
}

const mapDispatchToProps = {
  removeActionableMember,
  removeProgramMembersStart,
  resetPerkMembersRemoveState,
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogRemovePerkMembers)
