import React, { useState } from 'react'
import { Checkbox, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'

import { isRowSelected, getFullName } from '../admin/adminUtils'
import {
  setActionableMembers,
  setPage,
  setSort,
  toggleSelectAllMembers,
  toggleSelectMember,
  toggleSelectOnePageMembers,
} from '../../../redux/modules/teamPerkDetails'
import {
  selectPerkMembersFilteredBySearch,
  selectPerkMembersRows,
} from '../../../redux/selectors/teamProgramDetailsSelectors'
import AvatarMember from '../../common/AvatarMember'

import SortableTable from '../../common/SortableTable'
import HeaderTableMembers from '../common/HeaderTableMembers'
import ToolboxProgramMemberActions from './ToolboxProgramMemberActions'
import DialogRemovePerkMembers from './DialogRemovePerkMembers'

const useStyles = makeStyles({
  fullName: {
    fontWeight: 500,
  },
})

const TablePerkMembers = ({
  perkMembers,
  currentPageRows,
  isLoadingMembers,
  page,
  sort,
  selectedProgram,
  selectedMembers,
  emailFieldId,
  setPage,
  setSort,
  toggleSelectMember,
  toggleSelectOnePageMembers,
  toggleSelectAllMembers,
  setActionableMembers,
}) => {
  const classes = useStyles()
  const [showRemoveDialog, setShowRemoveDialog] = useState(false)

  const handleClickRemove = () => {
    setActionableMembers()
    setShowRemoveDialog(true)
  }

  const handleSelectMember = member => {
    toggleSelectMember(member)
  }

  const handleSelectOnePage = checked => {
    toggleSelectOnePageMembers({ checked, currentPageRows })
  }

  const handleSelectAll = checked => {
    toggleSelectAllMembers({ checked, allMembers: perkMembers })
  }

  const handleSort = (e, property) => {
    const isAsc = sort.field === property && sort.direction === 'asc'
    setSort({
      field: property,
      direction: isAsc ? 'desc' : 'asc',
    })
  }

  const columns = [
    {
      id: 'avatarId',
      label: '',
      sortable: false,
    },
    {
      id: 'lastName', // This field is named lastName for sorting purposes
      label: 'Name',
      sortable: true,
    },
    {
      id: emailFieldId,
      label: 'Email',
      sortable: true,
    },
  ]

  const rowCells = currentPageRows.map((member, index) => {
    const labelId = `members-table-checkbox-${index}`
    return {
      memberId: member.userId,
      cells: [
        {
          Component: (
            <Checkbox
              key={member.userId}
              checked={isRowSelected(selectedMembers, member)}
              inputProps={{ 'aria-labelledby': labelId }}
              onClick={() => handleSelectMember(member)}
            />
          ),
        },
        {
          Component: <AvatarMember member={member} />,
        },
        {
          labelId: labelId,
          Component: <Typography className={classes.fullName}>{getFullName(member)}</Typography>,
        },
        {
          Component: <Typography>{member[emailFieldId]}</Typography>,
        },
      ],
    }
  })

  return (
    <>
      <SortableTable
        data={perkMembers}
        rowCells={rowCells}
        page={page}
        setPage={setPage}
        loading={isLoadingMembers}
        HeaderComponent={
          <HeaderTableMembers
            checkbox
            teamMembers={perkMembers}
            columns={columns}
            rows={currentPageRows}
            selectedMembers={selectedMembers}
            sort={sort}
            onSelectOnePage={handleSelectOnePage}
            onSelectAll={handleSelectAll}
            onSort={handleSort}
            Toolbox={<ToolboxProgramMemberActions onShowRemoveDialog={handleClickRemove} />}
          />
        }
      />
      {showRemoveDialog && (
        <DialogRemovePerkMembers
          programId={selectedProgram?.programId}
          onClose={() => setShowRemoveDialog(false)}
          pricePerHeadInCents={selectedProgram?.discountAmountInPennies}
        />
      )}
    </>
  )
}

TablePerkMembers.defaultProps = {
  emailFieldId: 'username',
}

const mapStateToProps = state => {
  const perkMembers = selectPerkMembersFilteredBySearch(state)
  const currentPageRows = selectPerkMembersRows(state)
  const {
    page,
    search,
    sort,
    selectedProgram,
    isLoadingMembers,
    selectedMembers,
  } = state.teamPerkDetails

  return {
    perkMembers,
    currentPageRows,
    isLoadingMembers,
    page,
    search,
    sort,
    selectedProgram,
    selectedMembers,
  }
}

const mapDispatchToProps = {
  setPage,
  setSort,
  toggleSelectMember,
  toggleSelectOnePageMembers,
  toggleSelectAllMembers,
  setActionableMembers,
}

export default connect(mapStateToProps, mapDispatchToProps)(TablePerkMembers)
